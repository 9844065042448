<template>
	<div>
		<!-- FOOTER -->
		<footer class="container footerBottom">
			<span style="margin-top: 20px">
				© 2008-2020 广东智莺科技有限公司 版权所有 粤ICP备2021007547号
			</span>
			<span>粤公网安备 44049002000552</span>
		</footer>
	</div>
</template>

<script>
	export default {

	}
</script>

<style scoped>
	.footerBottom {
		display: flex;
		flex-direction: column;
		text-align: center;
		height: 85px;
		width: 100%;
		border-top: 1px solid #f2f2f2;
		color: #666666;
		font-size: 14px;
		margin-top: 10px;
	}
</style>
