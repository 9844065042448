<template>
		<div class="container">
			<div
				style="display: flex;justify-content: center;margin-top: 40px;margin-bottom: 10px;font-weight: 500;color: #333333;font-size: 14px;">
				联系我们
			</div>
			<div class="row justify-content-center">

				<div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
					<img alt="" src="http://www.fnuo123.com/View/index/img/index/pc_footer/weibo.png" />
					<div class="code-name">
						<img class="" src="http://www.fnuo123.com/View/index/img/comm/foot/weixin_2.png" alt="" />
						<span>企业微信</span>
					</div>
				</div>

				<div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
					<img alt="" src="http://www.fnuo123.com/View/index/img/index/weixin.png" />
					<div class="code-name">
						<img class="" :src="require('../../../assets/images/mini-weixin.png')" alt="" />
						<span>微信号</span>
					</div>
				</div>
			</div>
		</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [{
						imgUrl: require("../../../assets/images/index/zhuce.png"),
						title: "1.注册账号",
						desc: "注册账号填写资料直接前往创建应用",
					},
					{
						imgUrl: require("../../../assets/images/index/right.png"),
					},
					{
						imgUrl: require("../../../assets/images/index/app.png"),
						title: "2.创建应用",
						desc: "创建应用后选择想要使用的版本即可",
					},
					{
						imgUrl: require("../../../assets/images/index/right.png"),
					},
					{
						imgUrl: require("../../../assets/images/index/zhuanqian.png"),
						title: "3.开始赚钱",
						desc: "智能化后台帮您轻松管理，轻松赚钱",
					}
				]
			}
		},
		methods: {
			// 去登录
			goLogin: function() {
				this.$router.push("/login");
			},
		}
	}
</script>

<style scoped lang="scss">
	
	.footer2 {
		width: 100%;
		// height: 622px;
		background-image: url("../../../assets/images/index/bg1.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: relative;
		overflow: hidden;
		padding-bottom: 40px;

		.fontColor {
			font-size: 22px;
			color: #fff;
			opacity: 0.7;
		}

		.color1 {
			text-align: center;
			color: #fff;
		}



		.btn-content {
			margin-top: 3%;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;

			.btn {
				float: left;
				// display: inline-block;
				width: 220px;
				height: 50px;
				background: #ffffff;
				border-radius: 25px;
				color: #1b88ff;
				font-size: 18px;
				line-height: 50px;
				cursor: pointer;
				font-weight: bold;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.btn:active {
				background: rgb(238, 238, 238);
				zoom: 0.98;
			}
		}

		.footer-content {
			margin: 60px auto 0;
			width: 100%;
			text-align: center;

			.floatPng {
				// width: 80px;
				// height: 80px;
				// margin: auto;
				padding: 20px;
			}

			div {
				display: inline-block;
				width: 180px;
			}

			.ringPng {
				height: 26px;
				margin: 0 65px;
				display: inline-block;
				vertical-align: 126px;
			}
		}
	}

	.team-centent {
		display: flex;
		justify-content: space-between;
		width: 1200px;
		margin: 0 auto;
	}

	.item {

		flex-direction: column;
		align-items: center;
		color: white;
		margin-top: 40px;

		.icon {
			max-width: 65px;
			max-height: 65px;
		}

		.title {
			text-align: center;
		}

		.desc {
			text-align: center;
		}
	}

	.flex {
		display: flex;
	}

	.code-name {
		font-size: 14px;
		font-weight: 400;
		color: #666666;
		line-height: 20px;
		display: flex;
		justify-content: center;

		img {
			width: 20px;
			height: 18px;
			margin-right: 10px;
		}
	}
</style>
