<template>
	<div class="row">
		<!-- UI说大屏必须要一行显示5个 -->
		<div v-for="(item, index) in icons" :key="index"
			class="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2" :class="index % 5 === 0 ? ' offset-xl-1' : ''">
			<div class="card-cnt ">
				<img class="icon" alt="" :src="item.icon" />
				<div class="title">
					{{item.name}}
				</div>
				<div class="desc">
					{{item.desc}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				icons: [
					{
						icon: require("../../../assets/images/index/icons/1.png"),
						name: '云打包',
						desc: 'app和小程序可以一键云打包，且能做到不同模块ios，安卓装包大小自动伸缩行业唯一'
					},
					{
						icon: require("../../../assets/images/index/icons/2.png"),
						name: '数字门店',
						desc: '强大数字门店可以对不同实体有不同方案模板同时享受支付宝、微信多种营销奖励轻松月入百万'
					},
					{
						icon: require("../../../assets/images/index/icons/3.png"),
						name: '无限生成小程序',
						desc: '独家数字门店系统无限生成独立小程序，打造自己私域流量 '
					},
					{
						icon: require("../../../assets/images/index/icons/4.png"),
						name: '商家版APP',
						desc: '独立商家版APP一键开店随时随地查看店铺经营数据 '
					},
					{
						icon: require("../../../assets/images/index/icons/5.png"),
						name: '直播短视频',
						desc: '强大直播短视频功能，直播、点播、RTC、美颜等能力一站式完美结合，助你快速抢占流量风口'
					},
					{
						icon: require("../../../assets/images/index/icons/6.png"),
						name: '模块系统',
						desc: '行业唯一一家，淘客+数字门店+自营商城+供应链+区块营销等做模块系统随时拓展模块功能满足不同阶段营销需求'
					},
					{
						icon: require("../../../assets/images/index/icons/7.png"),
						name: '权益卡',
						desc: '一网打尽线上线下商城轻松赚佣金，支持淘宝、京东、等等上百种组合权益'
					},
					{
						icon: require("../../../assets/images/index/icons/8.png"),
						name: '中石化加油',
						desc: '全国中石化都有加油优惠，不仅加油优惠,还能充值加油卡，品质服务,方便快捷'
					},
					{
						icon: require("../../../assets/images/index/icons/9.png"),
						name: '电影票',
						desc: '全国院线在线选座,自动出票看电影省钱，app和小程序都能购买省钱'
					},
					{
						icon: require("../../../assets/images/index/icons/10.png"),
						name: '自营商城',
						desc: '自主经营，价格优惠自定义，带货更优惠'
					},
					{
						icon: require("../../../assets/images/index/icons/11.png"),
						name: '秒杀',
						desc: '特价促销秒杀，拉动店铺人气，提升商品流量和转化最有效的营销活动之一'
					},
					{
						icon: require("../../../assets/images/index/icons/12.png"),
						name: '广告',
						desc: '广告直接接入官方，无第三方分成'
					},
					{
						icon: require("../../../assets/images/index/icons/13.png"),
						name: 'app个性化',
						desc: '预设丰富的模块组件，后台可视化装修'
					},
					{
						icon: require("../../../assets/images/index/icons/14.png"),
						name: '制度中心',
						desc: '对不同实体有不同方案模板，和淘宝京东联盟等多个平台抽成设置'
					},
					{
						icon: require("../../../assets/images/index/icons/15.png"),
						name: '更多',
						desc: '更多功能在能力板块中查看'
					},
				]
			}
		}
	}
</script>

<style scoped>
	@media (max-width: 767px) {
		.card-cnt {
			margin-top: 1em;
			height: 200px;
			padding: 0 10px;
			border-radius: 10px;
		}
		.icon {
			width: 84px;
			height: 84px;
		}
	}
	
	@media (min-width: 767px) {
		.card-cnt {
			margin-top: 1em;
			height: 220px;
			padding: 0 10px;
			border-radius: 19px;
		}
		.icon {
			width: 100px;
			height: 100px;
		}
		
	}
	
	@media (min-width: 992px) {
		.card-cnt {
			margin-top: 2em;
			height: 240px;
			padding: 0 20px;
			border-radius: 19px;
		}
		.icon {
			width: 110px;
			height: 110px;
		}
	}
	
	
	.card-cnt {
		width: 100%;
		background: #FFFFFF;
		box-shadow: 0px 0px 41px 0px rgba(51, 51, 51, 0.09);
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.title {
		font-size: 18px;
		font-weight: 500;
		color: #333333;
		line-height: 21px;
	}

	.desc {
		text-align: center;
		margin-top: 12px;
		font-size: 12px;
		font-weight: 500;
		color: #999999;
		line-height: 17px;
	}
	
</style>
