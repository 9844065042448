<template>
	<div class="index-html">
		<div class="body" style="overflow-x: hidden;background-color: white;">
			<Navigator />

			<BannerSwiper />

			<div class="container marketing" style="margin-top: 5vw;">

				<div class="row justify-content-center">
					<div class="cont col-sm-12 col-md-12 col-lg-10 col-xl-10">
						<h3 class="index-title">深入开发10年，全渠道全场景</h3>
						<p class="index-desc">行业唯一后端开发语言GO为底层，前端为苹果oc和安卓java；服务器分布式布局多接点，数据库分布式自动伸缩；全新微服务架构每个模块可以独立部署；云原生架构可承载数亿级会员</p>
					</div>

				</div>

				<FurtherSwiper />

				<div class="row justify-content-center">
					<div class="cont col-sm-12 col-md-12 col-lg-8 col-xl-6" style="margin-top: 80px;">
						<h3 class="index-title">行业多年沉淀，汇聚时代智慧</h3>
						<p class="index-desc">功能种类齐全，想怎么玩就怎么玩</p>
					</div>

				</div>

				<Icons />



			</div>

			<Phone />

			<Manager />

			<Example />
			<Customer />
			<Step />
			<Contact />
			
			<ContactFloat />

			<Footer />
			
			
		</div>
	</div>
</template>

<script>
	import Navigator from './widgets/navigator.vue'
	import FurtherSwiper from "./widgets/Further-swiper.vue";
	import BannerSwiper from "./widgets/banner-swiper.vue";
	import Icons from "./widgets/Icons.vue"
	import Manager from "./widgets/Manager.vue"
	import Phone from "./widgets/Phone.vue"
	import Example from "./widgets/Example.vue"
	import Customer from "./widgets/Customer.vue"
	import Step from "./widgets/Step.vue"
	import Contact from "./widgets/Contact.vue"
	import ContactFloat from "./widgets/contact-float.vue"
	import Footer from './widgets/footer.vue'
	export default {
		components: {
			Navigator,
			FurtherSwiper,
			BannerSwiper,
			Icons,
			Manager,
			Phone,
			Example,
			Customer,
			Step,
			Contact,
			ContactFloat,
			Footer
		}
	}
</script>

<style scoped>
	/* GLOBAL STYLES
-------------------------------------------------- */
	/* Padding below the footer and lighter body text */

	.body {
		width: 100%;
		height: 100%;
	}

	.index-html {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		background: #fff;
		width: 100vw;
		min-height: 100vh;
		padding: 0;
	}

	/* 	.body {
		padding: 0;
		color: #5a5a5a;
		background-color: white !important;
	} */


	/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

	/* Carousel base class */
	.carousel {
		margin-bottom: 4rem;
	}

	/* Since positioning the image, we need to help out the caption */
	.carousel-caption {
		bottom: 3rem;
		z-index: 10;
	}

	/* Declare heights because of positioning of img element */
	.carousel-item {
		height: 23em;
	}

	.carousel-item>img {
		position: absolute;
		top: 0;
		left: 0;
		min-width: 100%;
		height: 32rem;
	}


	/* MARKETING CONTENT
-------------------------------------------------- */

	/* Center align the text within the three columns below the carousel */
	.marketing .col-lg-4 {
		margin-bottom: 1.5rem;
		text-align: center;
	}

	.marketing h2 {
		font-weight: 400;
	}

	.marketing .col-lg-4 p {
		margin-right: .75rem;
		margin-left: .75rem;
	}

	.marketing .cont {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;


	}


	/* Featurettes
------------------------- */

	.featurette-divider {
		margin: 5rem 0;
		/* Space out the Bootstrap <hr> more */
	}

	/* Thin out the marketing headings */
	.featurette-heading {
		font-weight: 300;
		line-height: 1;
		letter-spacing: -.05rem;
	}


	/* RESPONSIVE CSS
-------------------------------------------------- */

	@media (min-width: 40em) {

		/* Bump up size of carousel content */
		.carousel-caption p {
			margin-bottom: 1.25rem;
			font-size: 1.25rem;
			line-height: 1.4;
		}

		.featurette-heading {
			font-size: 50px;
		}
	}

	@media (min-width: 62em) {
		.featurette-heading {
			margin-top: 7rem;
		}
	}
</style>
