<template>
	<div>
		<b-navbar toggleable="lg" type="light" :fixed="fixed ? 'top' : ''" class="nav" :style="{
			backgroundColor: navTransparent ? '#00000000' : 'white',
			boxShadow: isNavTransparent ? '' : '0px 2px 30px 0px rgba(0, 0, 0, 0.11)'
		}" v-b-hover="hoverNav">
		
			<div class="container">
				<b-navbar-brand href="#">
					<img class="navbar-brand" alt="" :src="navTransparent ? logo1 : logo2"
						style="margin-right: 20px;height: 41px;" />

				</b-navbar-brand>

				<b-navbar-toggle target="nav-collapse" @click="onMenuClick">
					<b-icon-list :style="{
									 color: navTransparent ? 'white' : '#464646'
								 }"></b-icon-list>
				</b-navbar-toggle>

				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav>
						<template v-for="(item, idx) in items">
							<!-- 手机小屏幕 -->
							<template v-if="item.list && item.list.length > 0">
								<b-nav-item-dropdown :key="idx + 'md'" :text="item.title" right
									class="item d-block d-lg-none">
									<b-dropdown-item v-for="(sub, sIdx) in item.list" :key="'drop_' + idx + '_' + sIdx"
										class="drop-item" :active="sub.path === currentPath"
										@click.native="handleJump(idx, sIdx)">
										<el-image :src="sub.path === currentPath ? sub.icon1 : sub.icon" fit="fit"
											class="icon"></el-image>
										{{sub.title}}
									</b-dropdown-item>
								</b-nav-item-dropdown>
							</template>
							
							<b-nav-item v-if="item.list && item.list.length > 0" :key="idx"
								class="item d-none d-lg-block" @mouseenter="enter(idx)" @mouseleave="leave">
								<div >
									<span :style="{
										 color: navTransparent ? 'white' : (selectIndex === idx ? '#ff2020' : '#464646')
									 }">{{item.title}}</span>
									<div v-if="selectIndex === idx && !navTransparent" class="spin">
										<div class="spin-show"></div>
									</div>
								</div>
							</b-nav-item>

							<b-nav-item v-else class="item" :key="idx" @click="handleJump(idx)" :id="'pop' + idx"
								@mouseenter="enter(idx)" @mouseleave="leave">
								<span :style="{
									 color: navTransparent ? 'white' : (selectIndex === idx ? '#ff2020' : '#464646')
								 }">{{item.title}}</span>
								<div v-if="selectIndex === idx && !navTransparent" class="spin">
									<div class="spin-show"></div>
								</div>
							</b-nav-item>

						</template>

					</b-navbar-nav>

					<b-navbar-nav class="ml-auto">
						<div class="mt-2 mt-md-0" style="display: flex;align-items: center;cursor:pointer;"
							@click="goLogin">
							<span style="margin-left: 16px;font-size: 16px;" :style="{
									 color: navTransparent ? 'white' : '#464646'
								 }">登录</span>
						</div>
						
						<div class="mt-2 mt-md-0 ml-4" style="display: flex;align-items: center;cursor:pointer;"
							@click="goLogin">
							<span style="margin-left: 16px;font-size: 16px;" :style="{
									 color: navTransparent ? 'white' : '#464646'
								 }">免费试用</span>
						</div>
					</b-navbar-nav>

				</b-collapse>
				
				
			</div>
			
			<transition name="fade">
			<div v-if="showPop" class="menu" @mouseenter="inPopver = true" @mouseleave="inPopver = false">
				<div class="container" style="display: flex;flex-wrap: wrap;">
					<div v-for="(sub, sIdx) in items[2].list" :key="'drop_2' + '_' + sIdx"
						class="drop-item" :active="sub.path === currentPath"
						@click="handleJump(2, sIdx)">
						<el-image :src="sub.icon" fit="fit" class="icon"></el-image>
						<span>{{sub.title}}</span>
					
					</div>
				</div>
				
			</div>
			</transition>
		</b-navbar>

	</div>

</template>

<script>
	export default {
		props: {
			fixed: {
				type: Boolean,
				default: true
			}
		},
		computed: {
		    // 计算属性的 getter
		    showPop: function () {
		      return (this.selectIndex === 2 && this.inNav) || this.inPopver
		    },
			navTransparent: function () {
			  return this.isNavTransparent && !this.showPop && !this.inNav
			}
		},
		data() {
			return {
				selectIndex: -1,
				inNav: false,
				inPopver: false,
				isNavTransparent: true,
				logo1: require('@/assets/images/index/logo.png'),
				logo2: require('@/assets/images/index/logo2.png'),
				avatar1: require('@/assets/images/index/avatar.png'),
				avatar2: require('@/assets/images/index/avatar2.png'),
				currentPath: '',
				items: [{
						title: '首页',
						path: '/'
					},
					{
						title: '能力版块',
						path: '/index/ability'
					},
					{
						title: '生态体系',
						list: [{
								icon: require('@/assets/images/nav/1.png'),
								icon1: require('@/assets/images/nav/1-1.png'),
								title: '导购版块',
								path: '/index/guide'
							}, {
								icon: require('@/assets/images/nav/2.png'),
								icon1: require('@/assets/images/nav/2-1.png'),
								title: '权益卡版块',
								path: '/index/card'
							}, {
								icon: require('@/assets/images/nav/3.png'),
								icon1: require('@/assets/images/nav/3-1.png'),
								title: '本地生活版块',
								path: '/index/local'
							}, {
								icon: require('@/assets/images/nav/4.png'),
								icon1: require('@/assets/images/nav/4-1.png'),
								title: '区块链任务版块',
								path: '/index/block'
							}, {
								icon: require('@/assets/images/nav/5.png'),
								icon1: require('@/assets/images/nav/5-1.png'),
								title: '自营商城版块',
								path: '/index/mall'
							}, {
								icon: require('@/assets/images/nav/6.png'),
								icon1: require('@/assets/images/nav/6-1.png'),
								title: '数字门店版块',
								path: '/index/o2o'
							}, {
								icon: require('@/assets/images/nav/7.png'),
								icon1: require('@/assets/images/nav/7-1.png'),
								title: '供应链版块',
								path: '/index/provider'
							}, {
								// 	icon: require('@/assets/images/nav/8.png'),
								// 	icon1: require('@/assets/images/nav/8-1.png'),
								// 	title: '短视频直播',
								// 	path: ''
								// }, {
								// 	icon: require('@/assets/images/nav/9.png'),
								// 	icon1: require('@/assets/images/nav/9-1.png'),
								// 	title: '智能客服',
								// 	path: ''
								// }, {
								// 	icon: require('@/assets/images/nav/10.png'),
								// 	icon1: require('@/assets/images/nav/10-1.png'),
								// 	title: '游戏活动',
								// 	path: ''
								// }, {
								icon: require('@/assets/images/nav/8.png'),
								icon1: require('@/assets/images/nav/11-1.png'),
								title: '制度模式版块',
								path: '/index/institution'
							},
							{
								icon: require('@/assets/images/nav/9.png'),
								icon1: require('@/assets/images/nav/12-1.png'),
								title: '多用户商城版块',
								path: '/index/more-mall'
							},
							// {
							// 	icon: require('@/assets/images/nav/13.png'),
							// 	icon1: require('@/assets/images/nav/13-1.png'),
							// 	title: 'VR智能设备',
							// 	path: ''
							// }
						]
					},
					{
						title: '推介有奖',
						path: '/index/recommend'
					},
					{
						title: '开放平台'
					},
				]
			}
		},
		mounted() {
			console.log(this.$route.path)
			this.currentPath = this.$route.path
			if (this.currentPath === '/') {
				// 首页透明导航栏
				this.isNavTransparent = true
			} else {
				this.isNavTransparent = false
			}
			window.addEventListener('scroll', this.handleScroll, true)
		},
		methods: {
			goLogin: function() {
				this.$router.push("/login");
			},
			handleJump: function(index, idx) {
				const item = this.items[index]
				if (item.list && item.list.length > 0) {
					const sub = item.list[idx]
					if (sub.path && sub.path.length > 0) {
						this.$router.push(sub.path);
						return
					}
				}
				if (item.path && item.path.length > 0) {
					this.$router.push(item.path);
					return
				}

			},
			handleScroll(e) {
				if (this.currentPath === '/') {
					var scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop;
					this.isNavTransparent = scrollTop < 80
					// console.log(this.isNavTransparent)
				}

			},
			enter(index) {
				this.selectIndex = index
			},
			leave(e) {
				// this.selectIndex = -1
			},
			hoverNav(isHover) {
				this.inNav = isHover
			},
			onMenuClick(e) {
				this.isNavTransparent = false
			}
		}
	}
</script>

<style scoped lang="scss">
	@media (max-width: 767px) {}

	@media (min-width: 767px) {}

	@media (min-width: 992px) {}

	.menu {
		background-color: white;
		position: absolute;
		left: 0;
		right: 0;
		top: 61px;
		width: 100%;
		padding: 22px;
		box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.07) inset;
		z-index: 10;
		
	}

	.pop-menu {}

	.wide-popover {
		// max-width: 700px;
		// width: 500px;
		border: none;
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);
	}


	.nav {
		// box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.11);
		// background-color: white;
	}


	.item {
		font-size: 16px;
		color: #464646;
		line-height: 25px;
		margin-left: 0.5vw;
		margin-right: 0.5vw;
	}

	.icon {
		width: 40px;
		height: 40px;
		font-weight: 500;
		color: #464646;
		margin-right: 4px;
	}

	.drop-item {
		font-size: 16px;
		color: #464646;
		margin-top: 8px;
		padding: 4px 16px 4px 16px;
		width: 20%;
		border-radius: 4px;
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	.drop-item:hover {
		color: #ff2020;
	}

	.navbar-brand {
		padding: 0;
	}

	.navbar-toggler {
		border: none;
	}

	// .navbar-collapse {
	// 	background-color: white;
	// }


	/deep/.navbar-light .navbar-nav .nav-link {
		color: #464646;
		position: relative;
	}

	/deep/.dropdown-item.active {
		color: #fff;
		text-decoration: none;
		background-color: white;
		color: #FF2020;
		border-radius: 4px;
	}


	/deep/.dropdown-item:active {
		color: #fff;
		text-decoration: none;
		background-color: white;
		border-radius: 4px;
		color: #FF2020;
	}


	/deep/.dropdown-menu.show {
		display: flex;
		flex-wrap: wrap;
	}


	/deep/.navbar-light .navbar-nav .nav-link {
		color: #464646;
		position: relative;
	}

	.menu {
		display: flex;
		flex-wrap: wrap;
	}

	.spin {
		position: absolute;
		bottom: -8px;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;

		.spin-show {
			width: 27px;
			height: 4px;
			background: #FF2020;
		}

	}
	
	.fade-enter-active, .fade-leave-active {
	  transition: opacity .5s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	  opacity: 0;
	}
</style>
