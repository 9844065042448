<template>
	<div class="cnt">
		<div>
			<div class="wechat item" v-b-hover="hoverWechat">
				<img alt="" src="@/assets/images/index/wechat.png" />
			</div>
			<div class="item scroll-top" v-b-hover="hoverTop" @click="handleTop">
				<b-icon-arrow-up v-if="!topHover" font-scale="2"></b-icon-arrow-up>
				<span v-if="topHover">回到</span>
				<span v-if="topHover">顶部</span>
			</div>
		</div>
		<img v-if="wechatHover" class="qrcode" alt="" src="http://www.fnuo123.com/View/index/img/index/weixin.png" />
	</div>
</template>

<script>
	export default {
		data() {
			return {
				wechatHover: false,
				topHover: false
			}
		},
		methods: {
			hoverWechat(isHovered) {
				this.wechatHover = isHovered
			},
			hoverTop(isHovered) {
				this.topHover = isHovered
			},
			handleTop() {
				let distance = document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
				        let step = distance / 30; //每步的距离
				        (function jump() {
				            if (distance > 0) {
				                distance -= step;
				                window.scrollTo(0, distance);
				                setTimeout(jump, 10);
				            }
				        })();
			}
		}
	}
</script>

<style scoped lang="scss">
.cnt {
	position: fixed;
	left: 20px;
	top: 50vh;
	z-index: 50;
	display: flex;
	
	.item {
		width: 60px;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
	}
	
	.wechat {
		background-color: #35d28a;
	}
	
	.scroll-top {
		font-size: 20px;
		font-weight: 500;
		margin-top: 10px;
		background-color: #ff2020;
		text-align: center;
	}
	
	.qrcode {
		width: 140px;
		height: 140px;
		margin-left: 10px;
	}
}
</style>