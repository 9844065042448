<template>
	<div class="swiper-box">
		<swiper ref="mySwiper" class="swiper" :options="swiperOptions">
			<swiper-slide v-for="(item, index) in mod" :key="index">
				<div class="col" >
					<div class="item-img" :style="'backgroundImage:url(' + item.banner + ')'">
						<img :src="item.icon" class="icon" alt="" />
						<div style="font-size: 24px;font-weight: 500;">{{ item.title }}</div>
						<!-- <div style="font-size: 18px">{{ item.content }}</div> -->
					</div>
					<div v-if="item.data.length > 0" class="item-data">
						<div class="item-title">{{ item.title }}</div>
						<div class="item-for">
							<div v-for="(list, i) in item.data" :key="i" class="for-item">
								<img :src="list.icon" style="margin-right: 14px" alt="" />
								<div >
									<div style="color: #333333; font-size: 16px;font-weight: 500;">{{ list.title }}</div>
									<div style="color: #999999; font-size: 14px;">{{ list.content }}</div>
								</div>
							</div>
						</div>

						<div class="item-btn" @click="handleDetail(index)">
							<span v-if="mod[index].path && mod[index].path.length > 0">了解更多
							<span class="el-icon-arrow-right"></span></span>
							<span v-else>敬请期待</span>
						</div>
					</div>
					<div v-else class="item-data" style="display: fles;flex-direction: column;justify-content: center;align-items: center;">
						<img src="../../../assets/images/further/empty.png"  alt="" />
						<span style="color: #939393;font-size: 14px;margin-top: 20px;">敬请期待</span>
					</div>
				</div>
			</swiper-slide>
		</swiper>
		<div class="swiper-control-left el-icon-arrow-left" @click="slidePrev"></div>
		<div class="swiper-control-right el-icon-arrow-right" @click="slideNext"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				mod: [{
						banner: require("../../../assets/images/index/modules/guide.png"),
						title: "导购商城",
						icon: require("../../../assets/images/further/icon_1.png"),
						content: "购物返佣金",
						path: '/index/guide',
						data: [{
								title: "邀新拉新",
								content: "新时代商品模式",
								icon: require("../../../assets/images/further/laxin.png"),
							},
							{
								title: "视频商品",
								content: "快速建立用户群体",
								icon: require("../../../assets/images/further/shipin.png"),
							},
							{
								title: "权益卡",
								content: "超过200种平台权益",
								icon: require("../../../assets/images/further/quanyi.png"),
							},
							{
								title: "朋友圈",
								content: "一键快速分享商品拿佣金",
								icon: require("../../../assets/images/further/pengyouquan.png"),
							},
						],
					},
					{
						banner: require("../../../assets/images/index/modules/mall.png"),
						title: "自营商城",
						icon: require("../../../assets/images/further/icon_2.png"),
						content: "线上引领线下",
						path: '/index/mall',
						data: [{
								title: "积分营销",
								content: "购物返积分，积分兑好礼",
								icon: require("../../../assets/images/further/jifen.png"),
							},
							{
								title: "多人拼团",
								content: "拼团全新模式",
								icon: require("../../../assets/images/further/pingtuan.png"),
							},
							{
								title: "优惠券营销",
								content: "全局通用优惠券模块",
								icon: require("../../../assets/images/further/youhui.png"),
							},
							{
								title: "限时秒杀",
								content: "特价促销秒杀，拉动店铺人气",
								icon: require("../../../assets/images/further/miaosha.png"),
							},
						],
					},
					{
						banner: require("../../../assets/images/index/modules/o2o.png"),
						title: "数字门店",
						icon: require("../../../assets/images/further/icon_3.png"),
						content: "线上引领线下",
						path: '/index/o2o',
						data: [{
								title: "o2o商家端基础模块",
								content: "设置店铺打印管理，查看分账明细",
								icon: require("../../../assets/images/further/yijian.png"),
							},
							{
								title: "团购返利",
								content: "吸引新老客户上门消费",
								icon: require("../../../assets/images/further/tuangou.png"),
							},
							{
								title: "红包裂变",
								content: "邀请好友可领消费红包",
								icon: require("../../../assets/images/further/youxi.png"),
							},
							{
								title: "会员体系",
								content: "店铺复购率神器",
								icon: require("../../../assets/images/further/huiyuan.png"),
							},
						],
					},
					{
						banner: require("../../../assets/images/index/modules/institution.png"),
						icon: require("../../../assets/images/further/institution.png"),
						title: "权益卡",
						path: '/index/card',
						data: [{
								title: "京东PLUS",
								content: "会员7折起",
								icon: require("../../../assets/images/further/29.png"),
							},
							{
								title: "腾讯/爱奇艺",
								content: "尊享5折起",
								icon: require("../../../assets/images/further/30.png"),
							},
							{
								title: "美团/饿了么",
								content: "天天领红包",
								icon: require("../../../assets/images/further/31.png"),
							},
							{
								title: "星巴克",
								content: "特惠7折起",
								icon: require("../../../assets/images/further/32.png"),
							},
						],
					},
					{
						banner: require("../../../assets/images/index/modules/local.png"),
						icon: require("../../../assets/images/further/local.png"),
						title: "本地生活",
						path: '/index/local',
						data: [{
								title: "美团",
								content: "美团优选美团超市美团酒店",
								icon: require("../../../assets/images/further/3.png"),
							},
							{
								title: "肯德基/麦当劳",
								content: "手机点餐5折起",
								icon: require("../../../assets/images/further/2.png"),
							},
							{
								title: "饿了么",
								content: "生鲜红包最高领20元",
								icon: require("../../../assets/images/further/17.png"),
							},
							{
								title: "限时抢购",
								content: "特价促销秒杀，拉动店铺人气",
								icon: require("../../../assets/images/further/22.png"),
							},
						],
					},
					{
						banner: require("../../../assets/images/index/modules/zhidu.png"),
						icon: require("../../../assets/images/further/zhidu.png"),
						title: "制度模式",
						path: '/index/institution',
						data: [{
								title: "抽成设置",
								content: "淘宝京东联盟等多个平台抽成设置",
								icon: require("../../../assets/images/further/23.png"),
							},
							{
								title: "分佣方案",
								content: "对不同实体有不同方案模板",
								icon: require("../../../assets/images/further/8.png"),
							},
							{
								title: "订单结算方案",
								content: "自动化结算方式",
								icon: require("../../../assets/images/further/27.png"),
							},
							{
								title: "提现制度设置",
								content: "用户端和商家端的提现制度设置",
								icon: require("../../../assets/images/further/16.png"),
							},
						],
					},
					{
						banner: require("../../../assets/images/index/modules/block.png"),
						icon: require("../../../assets/images/further/block.png"),
						title: "区块链任务",
						path: '/index/block',
						data: [{
								title: "区域代理",
								content: "代理能获取该订单的部分提成",
								icon: require("../../../assets/images/further/4.png"),
							},
							{
								title: "全球分红",
								content: "最有效的业绩激增营销模式",
								icon: require("../../../assets/images/further/5.png"),
							},
							{
								title: "区块链",
								content: "行业独创粉丝券模式，促进粉丝裂变",
								icon: require("../../../assets/images/further/20.png"),
							},
							{
								title: "任务中心",
								content: "完成任务后可以获取佣金或积分",
								icon: require("../../../assets/images/further/18.png"),
							},
						],
					},
					{
						banner: require("../../../assets/images/index/modules/more-mall.png"),
						icon: require("../../../assets/images/further/more-mall.png"),
						title: "多用户商城",
						path: '/index/more-mall',
						data: [{
								title: "分销市场",
								content: "一键搭建,全员卖货",
								icon: require("../../../assets/images/further/6.png"),
							},
							{
								title: "入驻商家",
								content: "商家都有自己独立的管理后台",
								icon: require("../../../assets/images/further/8.png"),
							},
							{
								title: "打折促销",
								content: "强大的促销模块让商家商品不愁卖",
								icon: require("../../../assets/images/further/1.png"),
							},
							{
								title: "平台运营",
								content: "管理商城平台整体运营，数据分析",
								icon: require("../../../assets/images/further/19.png"),
							},
						],
					},
					{
						banner: require("../../../assets/images/index/modules/provider.png"),
						icon: require("../../../assets/images/further/provider.png"),
						title: "供应链",
						path: '/index/provider',
						data: [{
								title: "供应商管理",
								content: "",
								icon: require("../../../assets/images/further/8.png"),
							},
							{
								title: "云商品库",
								content: "",
								icon: require("../../../assets/images/further/7.png"),
							},
							{
								title: "数据管理",
								content: "",
								icon: require("../../../assets/images/further/28.png"),
							},
							{
								title: "财务中心",
								content: "",
								icon: require("../../../assets/images/further/16.png"),
							},
						],
					},
					{
						banner: require("../../../assets/images/index/modules/live.png"),
						icon: require("../../../assets/images/further/live.png"),
						title: "短视频直播",
						path: '',
						data: [{
								title: "直播",
								content: "",
								icon: require("../../../assets/images/further/11.png"),
							},
							{
								title: "互动",
								content: "",
								icon: require("../../../assets/images/further/9.png"),
							},
							{
								title: "美颜",
								content: "",
								icon: require("../../../assets/images/further/25.png"),
							},
							{
								title: "RTC",
								content: "",
								icon: require("../../../assets/images/further/15.png"),
							},
						],
					},
					{
						banner: require("../../../assets/images/index/modules/kefu.png"),
						icon: require("../../../assets/images/further/kefu.png"),
						title: "智能客服",
						path: '',
						data: [{
								title: "0.5秒快速响应",
								content: "",
								icon: require("../../../assets/images/further/6.png"),
							},
							{
								title: "99%回复准确",
								content: "",
								icon: require("../../../assets/images/further/13.png"),
							},
							{
								title: "24小时值守",
								content: "",
								icon: require("../../../assets/images/further/26.png"),
							},
							{
								title: "人工成本降低60%",
								content: "",
								icon: require("../../../assets/images/further/24.png"),
							},
						],
					},
					// {
					// 	banner: require("../../../assets/images/index/modules/game.png"),
					// 	icon: require("../../../assets/images/further/game.png"),
					// 	title: "游戏活动",
					// 	path: '',
					// 	data: [],
					// },
					// {
					// 	banner: require("../../../assets/images/index/modules/vr.png"),
					// 	icon: require("../../../assets/images/further/vr.png"),
					// 	title: "VR智能设备",
					// 	path: '',
					// 	data: [],
					// },
				],
				swiperOptions: {
					// slidesPerView: 4, //显示个数
					roundLengths: true,
					observer: true, //修改swiper自己或子元素时，自动初始化swiper
					observeParents: true, //修改swiper的父元素时，自动初始化swiper
					spaceBetween: 10,
					loop: true, // 循环模式选项
					pagination: {
						el: ".swiper-pagination",
					},
					autoplay: {
						delay: 3000,
						disableOnInteraction: false, // 手动切换之后继续自动轮播
					},
					navigation: {
						nextEl: ".swiper-control-right",
						prevEl: ".swiper-control-left",
					},
					breakpoints: {
						0: {
							slidesPerView: 1,//显示个数
							centeredSlides: false,
							spaceBetween: 30,
						},
						600: {
							slidesPerView: 2,//显示个数
							spaceBetween: 30,
							centeredSlides: false,
						},
						1000: {
							slidesPerView: 3,//显示个数
							spaceBetween: 30,
							centeredSlides: false,
						},
						1200: {
							slidesPerView: 4,//显示个数
							spaceBetween: 30,
							centeredSlides: false,
						},
					}
				},
			};
		},

		computed: {
			swipers() {
				return this.$refs.mySwiper;
			},
		},
		mounted() {},
		methods: {
			slidePrev() {
				this.$refs.mySwiper.$swiper.slidePrev();
			},
			slideNext() {
				this.$refs.mySwiper.$swiper.slideNext();
			},
			handleDetail(index) {
				const item = this.mod[index]
				console.log(item)
				if (item.path && item.path.length > 0) {
					this.$router.push(item.path);
					return
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	@media all {
		.swiper-control-left {
			left: -60px;
			background: #FEF1F1;
		}
		
		.swiper-control-right {
			right: -60px;
			background: #FEF1F1;
		}
	}
	@media (max-width: 900px) {
		.swiper-control-left ,
		.swiper-control-right {
			right: 0;
			background: #FEF1F100;
		}
	}
	
	@media (min-width: 1024px) and (max-width: 1150px) {
		.swiper-control-left ,
		.swiper-control-right {
			right: 0;
			background: #FEF1F100;
		}
	}
	@media (min-width: 1280px) and (max-width: 1400px) {
		.swiper-control-left ,
		.swiper-control-right {
			right: 0;
			background: #FEF1F100;
		}
	}
	
	
	
	.swiper-box {
		width: 100%;
		margin: 30px auto 0;
		height: 630px;
		position: relative;

		.swiper-control-left,
		.swiper-control-right {
			width: 40px;
			height: 40px;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto 0;
			color: #FF2020;
			font-size: 20px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			z-index: 90;
		}

		// .swiper-control-left {
		// 	left: -60px;
		// }

		// .swiper-control-right {
		// 	right: -60px;
		// }
	}

	.swiper-item {
		width: 328px;
	}

	.item-img {
		width: 100%;
		height: 144px;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		display: flex;
		color: #fff;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 27px;
		border-radius: 10px;

		.icon {
			margin: 10px 0 15px;
		}
	}

	.swiper-slide {
		display: flex;
		justify-content: center;
	}

	.item-data {
		width: 100%;
		height: 460px;
		background: linear-gradient(315deg, #FEEBF7 0%, #FEF2F0 100%);
		border-radius: 16px;
		display: flex;
		padding: 30px;
		box-sizing: border-box;
		flex-flow: column;

		.item-title {
			color: #333333;
			font-size: 24px;
			line-height: 24px;
			padding-bottom: 29px;
		}

		.item-for {
			display: flex;
			flex-flow: column;
			justify-content: space-between;
			height: 284px;

			.for-item {
				width: 100%;
				height: 44px;
				display: flex;
				align-items: center;
			}
		}

		.item-btn {
			width: 88px;
			height: 24px;
			background: #ffffff;
			border-radius: 15px;
			color: #FF2020;
			font-size: 13px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 30px auto 0;
			cursor: pointer;
		}
	}
</style>
