<template>
	<div class="example">
		<div class="container">
			<div class="row justify-content-center">
				<div class="cont col-sm-12 col-md-12 col-lg-8 col-xl-6" style="margin-top: 80px;">
					<div class="index-title">经典案例</div>
					<div class="index-desc">加入智莺，你也可以和他们一样优秀</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="margin-top: 40px;">
					<div class="icons ">
						<img v-for="(icon, index) in icons" :key="index" class="icon" alt="" :src="icon"/>
						
					</div>
				</div>
				
			</div>
			
			
			<div class="row justify-content-center">
				<div class="cont col-sm-12 col-md-12 col-lg-8 col-xl-6" style="margin-top: 80px;">
					<div class="index-title">好口碑源于强大技术研发团队</div>
					<div class="index-desc">8年的技术沉淀，平均每月4-6次的系统免费迭代更新，成就行业用户口碑</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				icons: [
					require("../../../assets/images/index/icons/IMG_1262.png"),
					require("../../../assets/images/index/icons/IMG_1263.png"),
					require("../../../assets/images/index/icons/IMG_1264.png"),
					require("../../../assets/images/index/icons/IMG_1266 2.png"),
					require("../../../assets/images/index/icons/IMG_1267 2.png"),
					require("../../../assets/images/index/icons/IMG_1268 2.png"),
					require("../../../assets/images/index/icons/IMG_1269.png"),
				]
			}
		}
	}
</script>

<style scoped lang="scss">
	@media (max-width: 767px) {
		.icon {
			width: 80px;
			height: 80px;
			margin-left: 20px;
			margin-right: 20px;
			margin-top: 20px;
		}
	}
	
	@media (min-width: 767px) {
		.icon {
			width: 110px;
			height: 110px;
			margin-left: 45px;
			margin-right: 45px;
			margin-top: 45px;
		}
	}
	
	@media (min-width: 992px) {
		.icon {
			width: 110px;
			height: 110px;
			margin-left: 45px;
			margin-right: 45px;
			margin-top: 45px;
		}
	}
	.example {
		background-image: url(../../../assets/images/index/bg.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		
		 .cont {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			
			.title {
				font-size: 24px;
				font-weight: 500;
				color: #333333;
				line-height: 45px;
				text-align: center;
			}
			
			.desc {
				font-size: 13px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				text-align: center;
				color: #B0B0B0;
			}
		}
		
		.icons {
			width: 100%;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			margin-bottom: 40px;
			
			
		}
		
	}
</style>
