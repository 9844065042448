<template>
	<div style="background-color: #F9F9F9;margin-top: 40px;">
		<div class="container">

			<div class="row">
				<div class="content-title intro-x">
					<h3 class="index-title">全新智能后台轻松管理</h3>
					<p class="index-desc">2020全新智能人性化后台</p>
				</div>
			</div>
			<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">

				<div class="pcAdmin-box">
					<div class="png-box intro-x">
						<img draggable="false" class="intro-x" src="@/assets/images/index/pc.png" alt="" />
					</div>

					<div class="CarouselBox intro-x">
						<el-carousel ref="admin" indicator-position="none" class="carousel" height="100%"
							:interval="5000">
							<el-carousel-item v-for="(item, index) in adminData" :key="index">
								<img draggable="false" class="itemImgUrl" :src="item.imgUrl" alt="" />
							</el-carousel-item>
						</el-carousel>

						<div class="indiButtom indicatorLeft el-icon-arrow-left intro-x" @click="onPrev"></div>
						<div class="indiButtom indicatorRight el-icon-arrow-right intro-x" @click="onNext"></div>
					</div>

					<div class="pcText-box intro-x">
						<p class="p1">智能助手</p>
						<p class="p2">自动检测后台配置，提供优化建议，为您的生意保驾护航</p>
					</div>
				</div>
				<!-- <div class="pcButtom intro-x" @click="getLogin">
				注册即可使用
			</div> -->
				<el-button class="pcButtom" type="danger" round @click="getLogin">注册即可使用</el-button>
			</div>


		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				adminData: [{
						imgUrl: require("../../../assets/images/index/AppleTV1.png"),
					},
					{
						imgUrl: require("../../../assets/images/index/AppleTV2.png"),
					},
					{
						imgUrl: require("../../../assets/images/index/AppleTV3.png"),
					},
				],
			}
		},
		methods: {
			onPrev: function() {
				this.$refs.admin.prev();
			},
			onNext: function() {
				this.$refs.admin.next();
			},
			// 去登录
			getLogin: function() {
				this.$router.push("/login");
			},
		}
	}
</script>

<style scoped lang="scss">
	@media (max-width: 767px) {
		.pcAdmin-box {
			width: 336px;
			height: 320px;
			position: relative;
			margin: 20px auto 0;

			.png-box {
				width: 336px;
			}
		}

		.CarouselBox {
			width: 328px;
			height: 194px;
			position: absolute;
			left: 4px;
			border-radius: 2px;
			top: 4px;
			z-index: 2;
		}

		.carousel {
			height: 194px;
			width: 328px;

		}
	}

	@media (min-width: 767px) {

		.pcAdmin-box {
			width: 636px;
			height: 500px;
			position: relative;
			margin: 30px auto 0;

			.png-box {
				width: 636px;
			}
		}

		.CarouselBox {
			width: 618px;
			height: 368px;
			position: absolute;
			left: 9px;
			border-radius: 2px;
			top: 9px;
			z-index: 2;
		}

		.carousel {
			height: 368px;
			width: 618px;


		}
	}

	.content-title {
		text-align: center;
		padding-top: 40px;
		width: 636px;
		margin: auto;

		.p1 {
			font-weight: bold;
			color: #333333;
		}

		.p2 {
			color: #b0b0b0;
			line-height: 21px;
		}
	}

	.pcAdmin-box {
		display: flex;
		justify-content: center;

		.png-box {
			position: absolute;
			z-index: 1;

			img {
				width: 100%;
				vertical-align: middle;
				margin: 0;
			}
		}
	}

	.CarouselBox {

		.itemImgUrl {
			width: 100%;
			height: 100%;
		}

		.indiButtom {
			top: 160px;
			position: absolute;
			width: 50px;
			height: 50px;
			text-align: center;
			line-height: 50px;
			background: rgba(51, 51, 51, 0.3);
			border-radius: 50%;
			color: #fff;
			cursor: pointer;
		}

		.indiButtom:hover {
			background: rgba(51, 51, 51, 0.2);
			font-size: 22px;
			transition: background-color 0.5s linear, font-size 0.5s linear;
		}

		.indiButtom:active {
			background: rgba(51, 51, 51, 0.1);
			transition: background-color 0.5s linear;
			zoom: 1.01;
		}

		.indicatorLeft {
			left: -100px;
		}

		.indicatorRight {
			right: -100px;
		}
	}

	.pcText-box {
		text-align: center;
		width: 100%;
		position: absolute;
		bottom: 0px;
		z-index: 2;

		.p1 {
			color: #333333;
			font-size: 18px;
			font-weight: bold;
		}

		.p2 {
			display: inline-block;
			width: 200px;
			font-size: 14px;
			color: #999999;
		}
	}

	.pcButtom {
		background: linear-gradient(270deg, #FF2020 0%, #FF4356 100%);
		// box-shadow: 0px 5px 10px 0px rgba(29, 128, 237, 0.3);
		margin: 20px;
	}
</style>
