<template>
	<div>
		<div class="footer2">
			<div class="container">
				<div class="titleH1 color1 intro-x">超过2000+企业信赖选择</div>
				<div class="titleH2 color1 intro-x">
					系统采用全新云原生生态架构，部署应用轻松自如、运行应用按需伸缩，秒杀传统Web框架，吊打传统IT模式，仅需三步，加入智莺生态
					共享时代红利！
				</div>
				<div class="row justify-content-center">
					<div v-for="(item, index) in list" :key="index"
						class="item col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2"
						:class="item.title ? 'flex' : 'hide-step'">
						<img v-if="item.title" draggable="false" class="icon" :src="item.imgUrl" alt="" />
						<img v-else draggable="false" :src="item.imgUrl" alt="" class="step" />
						<div class="title">{{item.title}}</div>
						<div class="desc">{{item.desc}}</div>
					</div>
				</div>
				<div class="btn-content" >
					<el-button class="btn" type="primary" round @click="goLogin">开启创业之路</el-button>
				</div>


			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [{
						imgUrl: require("../../../assets/images/index/zhuce.png"),
						title: "1.注册账号",
						desc: "注册账号填写资料直接前往创建应用",
					},
					{
						imgUrl: require("../../../assets/images/index/right.png"),
					},
					{
						imgUrl: require("../../../assets/images/index/app.png"),
						title: "2.创建应用",
						desc: "创建应用后选择想要使用的版本即可",
					},
					{
						imgUrl: require("../../../assets/images/index/right.png"),
					},
					{
						imgUrl: require("../../../assets/images/index/zhuanqian.png"),
						title: "3.开始赚钱",
						desc: "智能化后台帮您轻松管理，轻松赚钱",
					}
				]
			}
		},
		methods: {
			// 去登录
			goLogin: function() {
				this.$router.push("/login");
			},
		}
	}
</script>

<style scoped lang="scss">
	@media (max-width: 767px) {
		.titleH1 {
			font-size: 20px;
			margin-top: 40px;
		}

		.titleH2 {
			font-size: 16px;
			margin-top: 10px;
			text-align: center;
		}

		.titleH3 {
			font-size: 12px;
			margin-top: 10px;
		}

		.step {
			margin-top: 30px;
		}

		.title {
			font-size: 16px;
		}

		.desc {
			margin-top: 8px;
			font-size: 14px;
		}

		.hide-step {
			display: none;
		}

	}

	@media (min-width: 767px) {
		.titleH1 {
			font-size: 30px;
			margin-top: 80px;
		}

		.titleH2 {
			font-size: 16px;
			margin-top: 28px;
			text-align: center;
		}

		.titleH3 {
			font-size: 14px;
			margin-top: 20px;
		}

		.step {
			margin-top: 30px;
		}

		.title {
			font-size: 18px;
		}

		.desc {
			margin-top: 20px;
			font-size: 16px;
		}

		.hide-step {
			display: none;
		}
	}

	@media (min-width: 992px) {
		.titleH1 {
			font-size: 40px;
			margin-top: 80px;
		}

		.titleH2 {
			font-size: 18px;
			margin-top: 28px;
			text-align: center;
		}

		.titleH3 {
			font-size: 15px;
			margin-top: 20px;
		}

		.step {
			margin-top: 30px;
		}

		.title {
			font-size: 30px;
		}

		.desc {
			margin-top: 20px;
			font-size: 22px;
		}

		.hide-step {
			display: flex;
		}
	}

	.footer2 {
		width: 100%;
		// height: 622px;
		background-image: url("../../../assets/images/index/bg1.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: relative;
		overflow: hidden;
		padding-bottom: 40px;

		.fontColor {
			font-size: 22px;
			color: #fff;
			opacity: 0.7;
		}

		.color1 {
			text-align: center;
			color: #fff;
		}



		.btn-content {
			margin-top: 3%;
			text-align: center;

			.btn {
				// float: left;
				background: #ffffff;
				color: #1b88ff;
			}
		}

		.footer-content {
			margin: 60px auto 0;
			width: 100%;
			text-align: center;

			.floatPng {
				// width: 80px;
				// height: 80px;
				// margin: auto;
				padding: 20px;
			}

			div {
				display: inline-block;
				width: 180px;
			}

			.ringPng {
				height: 26px;
				margin: 0 65px;
				display: inline-block;
				vertical-align: 126px;
			}
		}
	}

	.team-centent {
		display: flex;
		justify-content: space-between;
		width: 1200px;
		margin: 0 auto;
	}

	.item {

		flex-direction: column;
		align-items: center;
		color: white;
		margin-top: 40px;

		.icon {
			max-width: 65px;
			max-height: 65px;
		}

		.title {
			text-align: center;
		}

		.desc {
			text-align: center;
		}
	}

	.flex {
		display: flex;
	}

	.code-name {
		font-size: 14px;
		font-weight: 400;
		color: #666666;
		line-height: 20px;
		display: flex;
		justify-content: center;

		img {
			width: 20px;
			height: 18px;
			margin-right: 10px;
		}
	}
</style>
