<template>
	<div class="container" style="margin-top: 80px;">
		<div class="row justify-content-center">
			<div class="col-lg-8 flex-center">
				<h3 class="index-title">多终端产品线 全覆盖</h3>
				<p class="index-desc">全面覆盖多终端产品线，智颖系统智能集成系统</p>
			</div>
		</div>
		<div class="cnt" style="margin-top: 80px;margin-bottom: 80px;">
			<div class="d-md-none"></div>
			<div class="d-none d-md-block">
				<div class="icons ">
					<div v-for="(item, index) in platforms1" :key="'p1' + index" class="item" >
						<img draggable="false" class="icon" :src="item.icon" alt="" />
						<div class="d-none d-lg-block">
							<div class="conte">
								<span class="title">{{item.title}}</span>
								<span class="desc d-none d-xl-block">{{item.desc}}</span>
							</div>
						</div>
						
					</div>
				</div>
			</div>
			
			<img draggable="false" src="@/assets/images/index/phone2.png" alt="" class="phone"/>
			<div class="d-md-none"></div>
			<div class="d-none d-md-block">
			<div class="icons">
				<div v-for="(item, index) in platforms2" :key="'p2' + index" class="item" >
					<img draggable="false" class="icon" :src="item.icon" alt="" />
					<div class="d-none d-lg-block">
						<div class="conte">
							<span class="title">{{item.title}}</span>
							<span class="desc d-none d-xl-block">{{item.desc}}</span>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				platforms1: [{
					icon: require("@/assets/images/index/platform/android.png"),
					title: '安卓',
					desc: '支持安卓终端全平台运营'
				},{
					icon: require("@/assets/images/index/platform/ios.png"),
					title: 'iOS',
					desc: '支持iOS终端全平台运营'
				}
				,{
					icon: require("@/assets/images/index/platform/h5.png"),
					title: 'H5',
					desc: '支持H5终端全平台运营'
				},{
					icon: require("@/assets/images/index/platform/baidu.png"),
					title: '百度小程序',
					desc: '支持百度小程序终端全平台运营'
				}],
				platforms2: [{
					icon: require("@/assets/images/index/platform/huawei.png"),
					title: '华为鸿蒙系统',
					desc: '支持华为鸿蒙系统终端全平台运营'
				},{
					icon: require("@/assets/images/index/platform/wx.png"),
					title: '微信小程序',
					desc: '支持微信小程序终端全平台运营'
				}
				,{
					icon: require("@/assets/images/index/platform/alipay.png"),
					title: '支付宝小程序',
					desc: '支持支付宝小程序终端全平台运营'
				},{
					icon: require("@/assets/images/index/platform/douyin.png"),
					title: '抖音小程序',
					desc: '支持抖音小程序终端全平台运营'
				}],
			}
		}
	}
</script>

<style scoped lang="scss">
	@media (max-width: 767px) {
		.icons {
			padding: 20px;
		}
	}
	
	@media (min-width: 767px) {
		.icons {
			padding: 20px;
		}
	}
	
	@media (min-width: 1024px) {
		.icons {
			padding: 40px;
		}
	}
	
	.flex-center {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.p2 {
		color: #B0B0B0;
	}

	// .cards {
	// 	display: flex;
	// 	flex-direction: column;
		
	// 	.item {
	// 		display: flex;
	// 		align-items: center;
	// 		width: 100%;
	// 		background: #FFFFFF;
	// 		box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
	// 		border-radius: 26px;
	// 		padding: 18px;
	// 		margin-bottom: 20px;
			
	// 		.icon {
	// 			width: 50px;
	// 			height: 50px;
	// 		}
	// 	}
	// }
	
	.cnt {
		display: flex;
		justify-content: space-between;
		
		.phone {
			width: 288px;
			height: 628px;
		}
		.icons {
			height: 628px;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			
			.item:hover {
				background: #FFFFFF;
				box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
			}
			
			.item {
				max-width: 400px;
				display: flex;
				align-items: center;
				// justify-content: center;
				width: 100%;
				background: #FAFAFA;
				border-radius: 26px;
				padding: 18px;
				margin-bottom: 20px;
				
				.icon {
					width: 60px;
					height: 60px;
				}
				.conte {
					display: flex;
					flex-direction: column;
					margin-left: 18px;
					.title {
						font-size: 18px;
						font-weight: 500;
						color: #333333;
						line-height: 25px;
					}
					.desc {
						font-size: 14px;
						font-weight: 400;
						color: #999999;
						line-height: 20px;
					}
				}
			}
		}
	}
</style>
