<template>
	<div class="banner-cnt">
		<swiper class="banner" ref="mySwiper" :options="swiperOptions" @slideChange="onSlideChange">
		  <swiper-slide v-for="(swiper, index) in swipers" :key="index">
			<!-- <el-image :src="image" fit="cover" class="banner" draggable="false"></el-image> -->
			<!-- <img :src="image" fit="cover" class="banner" draggable="false"/> -->
			<div class="img-wrap">
			    <img :src="swiper.bg" fit="cover" class="banner" draggable="false"/>
			</div>
			<div class="fg">
				<img :src="swiper.fg" fit="cover" class="container" draggable="false"/>
			</div>
			
		  </swiper-slide>

		
		  <!--分页器。如果放置在swiper-container外面，需要自定义样式。-->
		<!-- <div class="swiper-pagination"></div> -->
		
		
		</swiper>

		<div style="height: 60px;"></div>
		<div class="container customer">
			<div class="pagination " >
				<div v-for="(item, index) in swipers" :key="index">
					<div class="p-item" :class="index === activeIndex ? 'selected' : 'normal'" @click="onPagin(index)">
						
					</div>
					
				</div>
			</div>
			
			<div v-for="(item, index) in list" :key="index" class="item">
				<div class="value" :style="{
					color: color
				}">
					<countTo :startVal="item.startVal" :endVal="item.endVal" :duration="2000"></countTo>{{item.value}}
				</div>
				<div class="title">
					{{item.title}}
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import countTo from "vue-count-to";
	
	export default {
		components:{countTo,},
		data() {
			return {
				activeIndex: 0,
				color: '#FF2020',
				swiperOptions: {
				 
				  loop: true, // 循环模式选项
				  // autoHeight: true, //高度随内容变化
				     
				  // 如果需要分页器
				  pagination: {
				    el: ".swiper-pagination",
					clickable: true //允许分页点击跳转
				  },
				  effect: "fade",
				  fadeEffect: {
				    crossFade: true,
				  },
				  autoplay: {
				    delay: 3000,
				    disableOnInteraction: false, // 手动切换之后继续自动轮播
				  },
				},
				slide: 0,
				sliding: null,
				swipers: [
					{
						bg: require('@/assets/images/index/swiper-1.png'),
						fg: require('@/assets/images/index/swiper-1-1.png'),
						color: '#FF2020'
					},
					{
						bg: require('@/assets/images/index/swiper-2.png'),
						fg: require('@/assets/images/index/swiper-2-1.png'),
						color: '#7B66EC'
					},
					{
						bg: require('@/assets/images/index/swiper-3.png'),
						fg: require('@/assets/images/index/swiper-3-1.png'),
						color: '#1F82EC'
					}
				],
				images: [
					require('@/assets/images/index/swiper-1.png'),
					require('@/assets/images/index/swiper-2.png'),
					require('@/assets/images/index/swiper-3.png'),
				],
				list: [{
						startVal: 0,
						endVal: 1000,
						value: '+',
						title: '客户品牌数'
					},
					{startVal: 0,
						endVal: 6000,
						value: '万人+',
						title: '累计用户数'
					},
					{startVal: 0,
						endVal: 1000,
						value: '亿元+',
						title: '累计销售额'
					},
					{startVal: 0,
						endVal: 99,
						value: '%',
						title: '系统稳定性'
					}
				],
			}
		},
		methods: {
			onSlideChange(e) {
				const index = (e.activeIndex + this.swipers.length - 1) % this.swipers.length
				console.log('slide change', e.activeIndex, index);
				this.color = this.swipers[index].color
				this.activeIndex = index
			},
			onPagin(index) {
				
			}
		}
	}
</script>

<style scoped lang="scss">
	@media (max-width: 576px) {
		.value {
			font-size: 14px;
		}
	
		.title {
			font-size: 14px;
		}
	
		.banner {
			height: 200px;
		}
	}
	@media (min-width: 768px) {
		.value {
			font-size: 14px;
		}

		.title {
			font-size: 14px;
		}

		.banner {
			height: 300px;
		}
	}

	@media (min-width: 767px) {
		.value {
			font-size: 24px;
		}

		.title {
			font-size: 16px;
		}

		.banner {
			height: 350px;
		}
	}

	@media (min-width: 1024px) {
		.value {
			font-size: 32px;
		}

		.title {
			font-size: 20px;
		}

		.banner {
			height: 400px;
		}
	}
	@media (min-width: 1280px) {
		.value {
			font-size: 32px;
		}
	
		.title {
			font-size: 20px;
		}
	
		.banner {
			height: 500px;
		}
	}
	
	.fg {
		position: absolute;
		left: 0;
		// top: 80px;
		right: 0;
		bottom: 0;
	}
	
	.fg > img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	 .img-wrap {
	        display: inline-block;
	    }
	
	    .img-wrap img {
	    	// width: 100%;
	    	// vertical-align: middle;
			object-fit: cover;
	    }

	.banner-cnt {
		position: relative;
	}
	.banner {
		width: 100vw;
	}

	.customer {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		display: flex;
		background: #FFFFFF;
		box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.05);
		border-radius: 13px;
		padding: 12px;

		.item {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.value {
				font-weight: 500;
				color: #1890FF;
				font-family: Medium;
			}

			.title {
				margin-top: 4px;
				font-weight: 500;
				color: #4F5362;
			}
		}
	}
	
	.pagination {
		position: absolute;
		left: 0;
		right: 0;
		top: -20px;
		display: flex;
		z-index: 10;
		
		.p-item {
			width: 5vw;
			height: 3px;
			background: #FFFFFF;
			border-radius: 2px;
			margin-right: 1vw;
		}
		
		.normal{
			opacity: 0.3;
		}
		.selected{
			opacity: 1;
		}
	}
</style>
