var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-navbar',{directives:[{name:"b-hover",rawName:"v-b-hover",value:(_vm.hoverNav),expression:"hoverNav"}],staticClass:"nav",style:({
		backgroundColor: _vm.navTransparent ? '#00000000' : 'white',
		boxShadow: _vm.isNavTransparent ? '' : '0px 2px 30px 0px rgba(0, 0, 0, 0.11)'
	}),attrs:{"toggleable":"lg","type":"light","fixed":_vm.fixed ? 'top' : ''}},[_c('div',{staticClass:"container"},[_c('b-navbar-brand',{attrs:{"href":"#"}},[_c('img',{staticClass:"navbar-brand",staticStyle:{"margin-right":"20px","height":"41px"},attrs:{"alt":"","src":_vm.navTransparent ? _vm.logo1 : _vm.logo2}})]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"},on:{"click":_vm.onMenuClick}},[_c('b-icon-list',{style:({
								 color: _vm.navTransparent ? 'white' : '#464646'
							 })})],1),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',[_vm._l((_vm.items),function(item,idx){return [(item.list && item.list.length > 0)?[_c('b-nav-item-dropdown',{key:idx + 'md',staticClass:"item d-block d-lg-none",attrs:{"text":item.title,"right":""}},_vm._l((item.list),function(sub,sIdx){return _c('b-dropdown-item',{key:'drop_' + idx + '_' + sIdx,staticClass:"drop-item",attrs:{"active":sub.path === _vm.currentPath},nativeOn:{"click":function($event){return _vm.handleJump(idx, sIdx)}}},[_c('el-image',{staticClass:"icon",attrs:{"src":sub.path === _vm.currentPath ? sub.icon1 : sub.icon,"fit":"fit"}}),_vm._v(" "+_vm._s(sub.title)+" ")],1)}),1)]:_vm._e(),(item.list && item.list.length > 0)?_c('b-nav-item',{key:idx,staticClass:"item d-none d-lg-block",on:{"mouseenter":function($event){return _vm.enter(idx)},"mouseleave":_vm.leave}},[_c('div',[_c('span',{style:({
									 color: _vm.navTransparent ? 'white' : (_vm.selectIndex === idx ? '#ff2020' : '#464646')
								 })},[_vm._v(_vm._s(item.title))]),(_vm.selectIndex === idx && !_vm.navTransparent)?_c('div',{staticClass:"spin"},[_c('div',{staticClass:"spin-show"})]):_vm._e()])]):_c('b-nav-item',{key:idx,staticClass:"item",attrs:{"id":'pop' + idx},on:{"click":function($event){return _vm.handleJump(idx)},"mouseenter":function($event){return _vm.enter(idx)},"mouseleave":_vm.leave}},[_c('span',{style:({
								 color: _vm.navTransparent ? 'white' : (_vm.selectIndex === idx ? '#ff2020' : '#464646')
							 })},[_vm._v(_vm._s(item.title))]),(_vm.selectIndex === idx && !_vm.navTransparent)?_c('div',{staticClass:"spin"},[_c('div',{staticClass:"spin-show"})]):_vm._e()])]})],2),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('div',{staticClass:"mt-2 mt-md-0",staticStyle:{"display":"flex","align-items":"center","cursor":"pointer"},on:{"click":_vm.goLogin}},[_c('span',{staticStyle:{"margin-left":"16px","font-size":"16px"},style:({
								 color: _vm.navTransparent ? 'white' : '#464646'
							 })},[_vm._v("登录")])]),_c('div',{staticClass:"mt-2 mt-md-0 ml-4",staticStyle:{"display":"flex","align-items":"center","cursor":"pointer"},on:{"click":_vm.goLogin}},[_c('span',{staticStyle:{"margin-left":"16px","font-size":"16px"},style:({
								 color: _vm.navTransparent ? 'white' : '#464646'
							 })},[_vm._v("免费试用")])])])],1)],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.showPop)?_c('div',{staticClass:"menu",on:{"mouseenter":function($event){_vm.inPopver = true},"mouseleave":function($event){_vm.inPopver = false}}},[_c('div',{staticClass:"container",staticStyle:{"display":"flex","flex-wrap":"wrap"}},_vm._l((_vm.items[2].list),function(sub,sIdx){return _c('div',{key:'drop_2' + '_' + sIdx,staticClass:"drop-item",attrs:{"active":sub.path === _vm.currentPath},on:{"click":function($event){return _vm.handleJump(2, sIdx)}}},[_c('el-image',{staticClass:"icon",attrs:{"src":sub.icon,"fit":"fit"}}),_c('span',[_vm._v(_vm._s(sub.title))])],1)}),0)]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }