<template>
	<div>
		<div class="container customer">
			<div v-for="(item, index) in list" :key="index" class="item">
				<div class="value">
					{{item.value}}
				</div>
				<div class="title">
					{{item.title}}
				</div>
			</div>
		</div>

		<vue-seamless-scroll :data="newsList" :class-option="{
		    direction: 2,
		    limitMoveNum: 2,
		  }" class="seamless-warp2 intro-x">
			<div class="key-y">
				<div v-for="(item, index) in teamData4" :key="'item' + index" style="margin-left: 40px">
					<img class="itemImgUrl" :src="item.imgUrl" alt="" />

					<p class="itemContent">{{ item.content }}</p>
				</div>
			</div>
		</vue-seamless-scroll>

		<vue-seamless-scroll :data="newsList" :class-option="{
		    direction: 3,
		    limitMoveNum: 2,
		  }" class="seamless-warp2 intro-x">
			<div class="key-y">
				<div v-for="(item, index) in teamData5" :key="'item' + index" style="margin-left: 40px">
					<img class="itemImgUrl" :src="item.imgUrl" alt="" />

					<p class="itemContent">{{ item.content }}</p>
				</div>
			</div>
		</vue-seamless-scroll>

		<vue-seamless-scroll :data="newsList" :class-option="optionLeft" class="seamless-warp2 intro-x">
			<div class="key-y">
				<div v-for="(item, index) in teamData6" :key="'item' + index" style="margin-left: 40px">
					<img class="itemImgUrl" :src="item.imgUrl" alt="" />

					<p class="itemContent">{{ item.content }}</p>
				</div>
			</div>
		</vue-seamless-scroll>
	</div>
</template>

<script>
	import vueSeamlessScroll from "vue-seamless-scroll";
	export default {
		components: {
			vueSeamlessScroll
		},
		data() {
			return {
				list: [{
						value: '1000+',
						title: '客户品牌数'
					},
					{
						value: '6000万人+',
						title: '累计用户数'
					},
					{
						value: '1000亿元+',
						title: '累计销售额'
					},
					{
						value: '99.99%',
						title: '系统稳定性'
					}
				],
				teamData: [{
						imgUrl: require("../../../assets/images/touxiang/1.png"),
						content: "智莺平台有宝妈们中意的海量优质商品，商学院里面还有简单明了的教程，一看就懂，宝妈们在家带宝宝就能赚钱，还能发展自己的团队，比上班还赚得多！",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/2.png"),
						content: "上班族很辛苦，用智莺平台0门槛入门，利用社交网发展团队，团队消费自己坐享收益。利用工作之余的时间就能实现双向收入，变身斜杠青年！",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/3.png"),
						content: "初期创业有做APP的想法，但是招聘研发团队需要大量资金，使用智莺生活SaaS系统，没有库存压力，只需要将心思放到运营商就可以了，大大节省了成本！",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/4.png"),
						content: "随着微商群体越来越多，竞争越来越激烈，收入难增加，使用智莺生活可以利用购物省钱福利刺激粉丝消费热情，将粉丝变成自己的团队，开启另一条造富之路!",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/5.png"),
						content: "朋友使用购物返利软件比较多，我就了解了一下，没想到通过智莺，我居然拥有自己的返利App，不仅可以让朋友获得实惠，自己也能有一份可观收入，真是不错！",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/6.png"),
						content: "一直以来都在考察导购软件系统，经朋友介绍接触了智莺系统，他们的系统界面使用起来比较舒服，功能也很强大，产品能够满足市场推广需要。",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/7.png"),
						content: "有很多同行都有提到过智莺，自己也去了解一下，发现这个系统不管是从软件功能方面还是从整个服务体验来说都是一流的。",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/8.png"),
						content: "在其他平台上接触过一些软件服务商，都只管功能不管用户体验的。智莺完全不同，他们软件都是自己团队开发的，多年市场基础，注重用户体验，整体感觉非常好。",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/9.png"),
						content: "自从我选择智莺系统之后，我就知道自己没有选错，从软件购买到后面服务都是非常好的，每一步都有人性化引导，如果不明白也有专人指导，使用方便。",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/10.png"),
						content: "想要做导购软件，优先考虑智莺系统，智莺系统给你的不仅仅是一个导购软件，它还能够给你更多的惊喜和意外，新功能的创新，软件的迭代速度等都非常优秀。",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/11.png"),
						content: "做了多年的淘客，也用了好几家的软件，最后觉得还是智莺系统最好用。我们追求的是稳定.功能齐全.好管理，智莺就很好的满足了我的需求。",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/12.png"),
						content: "第一次做导购软件，很多东西都不知道怎么办，好在他们的服务特别周到，需要的资料都会指导你申请，自己不会弄的也立马给你解决，办事效率高，关键是省心。",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/13.png"),
						content: "前后对比了很多家，抱着试一试的想法，试用了智莺系统，发现他们功能、页面都比较符合我的要求。希望智莺越来越好",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/14.png"),
						content: "其他软件主流的功能，智莺都有，而且体验更好！产品库很强大，代理模式也很棒！智莺系统的确很好用吗，省心效率又高！",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/15.png"),
						content: "关于淘客软件哪个好用，我觉得还是智莺系统好用。最喜欢智莺App的界面，很简洁，容易操作，还可以根据我的需求自定义调整。",
					},
					{
						imgUrl: require("../../../assets/images/touxiang/16.png"),
						content: "使用智莺系统也有一段时间了，收入还是很不错的，这段时间来，行业有什么新政策调整，他们都会及时更新功能，让你的利益最大化，少踩雷，多赚钱。",
					},
				],
				newsList: [{
						title: "A simple, seamless scrolling for Vue.js",
					},
					{
						title: "A curated list of awesome things related to Vue.js",
					},
				],
			}
		},
		computed: {
			teamData4: function() {
				return this.teamData.slice(0, 4);
			},
			teamData5() {
				let arr = JSON.parse(JSON.stringify(this.teamData));
				for (let i = 0, len = arr.length; i < len; i++) {
					const ran = parseInt(Math.random() * len);
					const temp = arr[ran];
					arr[ran] = arr[i];
					arr[i] = temp;
				}
				return arr;
			},
			teamData6() {
				let arr = JSON.parse(JSON.stringify(this.teamData));
				for (let i = 0, len = arr.length; i < len; i++) {
					const ran = parseInt(Math.random() * len);
					const temp = arr[ran];
					arr[ran] = arr[i];
					arr[i] = temp;
				}
				return arr;
			},
			optionLeft() {
				return {
					direction: 2,
					limitMoveNum: 2,
				};
			},
		},
	}
</script>

<style scoped lang="scss">
	@media (max-width: 767px) {
		.value {
			font-size: 14px;
		}

		.title {
			font-size: 14px;
		}
	}

	@media (min-width: 767px) {
		.value {
			font-size: 24px;
		}

		.title {
			font-size: 16px;
		}
	}

	@media (min-width: 992px) {
		.value {
			font-size: 32px;
		}

		.title {
			font-size: 20px;
		}
	}
	
	.value {
		font-family: Medium;
	}


	.customer {
		display: flex;
		margin-top: 40px;
		margin-bottom: 40px;

		.item {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.value {
				font-weight: 500;
				color: #FF2020;
			}

			.title {
				margin-top: 4px;
				font-weight: 500;
				color: #4F5362;
			}
		}
	}

	.itemContent {
		height: 104px;
		font-size: 13px;
		margin: auto;
		overflow: hidden;
		font-weight: 400;
		padding: 0 20px;
		margin-top: 15px;
	}

	.key-y {
		width: 100%;
		white-space: nowrap;
		overflow-x: auto;
		margin-bottom: 5px;
		margin-top: 5px;

		.itemImgUrl {
			float: left;
			width: 80px;
			height: 80px;
		}

		.itemContent {
			font-size: 12px;
			float: left;
			color: #4f5362;
			max-width: 330px;
			white-space: normal;
			height: 100%;
			text-align: left;
		}

		div {
			display: inline-block;
			overflow: hidden;
			width: 434px;
			height: 96px;
			padding: 8px;
			background: #f9f9f9;
			border-radius: 51px;
		}
	}
</style>
